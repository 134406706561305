@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&display=swap');

:root {
  --main-bg-color: #222;
  --separator-color: #353535;
  --text-color: #fff;
  --brand-color-primary: #B6465F
}

h1, h2, h3, h4, .title {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-weight: 400;

}

body {
  background: var(--main-bg-color);

  * {
    color: var(--text-color);
    text-decoration: none;

  }

  a {
    color: var(--brand-color-primary);
  }

}

p {
  font-family: 'Montserrat', serif;
}

#page {
  display: flex;


  flex-direction: column;

  main {
    width: 90vw;
    max-width: 1500px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;

    .title {
      text-align: center;
      padding: 20px;
      margin-bottom: 30px;
      margin-top: 60px;

      h1, h2, h3, h4 {
        text-align: center;
        border-bottom: 1px solid var(--separator-color);
        display: inline;
        padding: 20px 30px;
      }
    }

  }
}

header {
  border-bottom: 1px solid var(--separator-color);
  display: flex;
  padding: 15px;


  .mobile-menu-handler {

    align-items: center;
    cursor: pointer;
    display: none;

    svg {
      width: 28px;

      path {
        fill: white;
      }

      .bg {
        fill: var(--brand-color-primary);
        opacity: 0.7;
      }
    }
  }

  nav {
    display: flex;
    flex-direction: row;
    flex: 1;


  }

  .logo {
    background: var(--brand-color-primary);
    display: inline-block;
    box-shadow: 4px 4px 0px black;
    margin: 20px;
    align-items: center;
    padding: 9px 10px 5px;
  }

  .menu, .socials {
    ul {
      list-style: none;
      display: flex;
      flex-direction: row;

      li {
        font-family: 'Oswald', serif;
        text-transform: uppercase;
        margin: 10px 20px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid transparent;

        a {
          color: #999999;
          font-size: 14px;

        }


        &:hover, &:has([aria-current]) {
          border-bottom: 1px solid var(--brand-color-primary);

          a {
            color: white;
          }

          path {
            fill: white;
          }
        }

      }
    }
  }

  .menu {
    flex: 1;

    li:first-child {
      position: relative;
      top: 2px;
    }
  }

  .socials {
    ul {
      li {

      }
    }
  }
}


#page.home {
  justify-content: stretch;
  align-items: stretch;
  height: 100vh;

  .title {
    margin-top: 0;
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      max-width: 700px;
      padding: 0 20px;
      text-align: center;
      font-size: 20px;
      line-height: 32px;
    }
  }
}

#page.course {
  .content {
    display: flex;
    font-size: 16px;
    line-height: 29px;
  }

  .description {
    flex: 4;
  }

  .content-table {
    flex: 2;
    border-left: 1px solid var(--separator-color);

    span {
      background: var(--separator-color);
      padding: 10px 20px;
      font-family: Oswald;
      text-transform: uppercase;
    }

    ul {
      li {
        padding: 10px;
      }
    }
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: 25px;
    }
    .content-table {
      display: block;
      border: 1px solid var(--separator-color);

      span {
        display: block;

        text-align: center;
      }
    }
    .content {
      flex-direction: column;

      > div {
        margin-bottom: 30px;
      }
    }
    .buy {
      text-align: center;
    }
  }
}

.button {
  background: var(--brand-color-primary);
  color: white;
  text-transform: uppercase;
  font-family: Oswald;
  padding: 10px 20px;
  box-shadow: 4px 4px 0px black;
  display: inline-block;

  &:hover {
    background: white;
    color: var(--brand-color-primary);
  }
}

.buy {
  margin-top: 50px;
  border-top: 1px solid var(--separator-color);
  padding-top: 20px;

  .price {
    font-family: Oswald;
    font-size: 35px;
    margin-bottom: 20px;
  }
}

@media (max-width: 560px) {
  header {
    .mobile-menu-handler {
      display: flex;
      flex: 1;
      justify-content: end;
      margin-right: 20px;
    }

    nav {
      flex-direction: column;
      display: none;
      background: var(--main-bg-color);

      &[data-show="true"] {
        display: block;
        position: absolute;
        inset: 0;
        top: 114px;

        ul {
          padding: 0;
          margin: 0;
        }

        .menu {
          ul {
            flex-direction: column;

            li {
              padding: 15px 30px;
              margin: 0;
              border-bottom: 1px solid var(--separator-color);
            }
          }
        }

        .socials {
          margin-top: 15px;

          ul {
            justify-content: center;
          }
        }
      }

    }
  }


}